import {intervalToDuration, parseISO} from 'date-fns'
import {useEffect, useMemo, useState} from 'react'

export interface TimeAgoProps
  extends React.DetailedHTMLProps<React.TimeHTMLAttributes<HTMLTimeElement>, HTMLTimeElement> {
  dateTime: string
}

export const TimeAgo: React.FC<TimeAgoProps> = ({dateTime, ...rest}) => {
  const startDate = useMemo(() => parseISO(dateTime), [dateTime])
  const [timeAgo, setTimeAgo] = useState(formatTimeAgo(startDate))

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(formatTimeAgo(startDate))
    }, 1000 * 60)
    return () => clearInterval(interval)
  }, [startDate])

  return (
    <time dateTime={dateTime} {...rest}>
      {timeAgo}
    </time>
  )
}

function formatTimeAgo(date: Date) {
  const duration = intervalToDuration({start: date, end: new Date()})
  if (duration.years) return `${duration.years} ${duration.years === 1 ? 'year' : 'years'} ago`
  if (duration.months) return `${duration.months} ${duration.months === 1 ? 'month' : 'months'} ago`
  if (duration.weeks) return `${duration.weeks} ${duration.weeks === 1 ? 'week' : 'weeks'} ago`
  if (duration.days) return duration.days === 1 ? 'yesterday' : `${duration.days} days ago`
  if (duration.hours) return `${duration.hours} ${duration.hours === 1 ? 'hour' : 'hours'} ago`
  if (duration.minutes) return `${duration.minutes} ${duration.minutes === 1 ? 'minute' : 'minutes'} ago`
  // if (duration.seconds) return `${duration.seconds} ${duration.seconds === 1 ? 'second' : 'seconds'} ago`
  return 'just now'
}
